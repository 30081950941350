<template>
    <div>
        <div class="lgame-elimination-bracket-container d-flex justify-content-center" :style="{
            '--first-place': 'url(' + require('@/assets/1st-place-medal.png') + ')',
            '--second-place': 'url(' + require('@/assets/2nd-place-medal.png') + ')',
            '--third-place': 'url(' + require('@/assets/3rd-place-medal.png') + ')',
            '--trophy': 'url(' + require('@/assets/trophy-winner.png') + ')',
        }">
            <div class="bracket-controls p-2">
                <!-- <button class="btn-bracket-control zoom-out-control d-flex justify-content-center align-items-center mb-2">-</button>
                 <button class="btn-bracket-control zoom-in-control d-flex justify-content-center align-items-center mb-2">+</button>
                 <button class="btn-bracket-control move-control d-flex justify-content-center align-items-center mb-2">M</button>
                 <button class="btn-bracket-control display-control d-flex justify-content-center align-items-center my-4">B</button>-->
                <button data-toggle="modal" data-target="#lgame-report-score-dialog" ref="triggerDialog"
                    hidden></button>
            </div>
            <div class="bracket-container-wrapper d-flex flex-column justify-content-start align-items-center"
                v-if="players">
                <div class="bracket-container d-flex flex-column justify-content-start align-items-start w-100 p-3"
                    ref="bracketcontainer">
                    <div class="round-number-container d-flex align-items-center mb-2 ml-2">
                        <div class="round-number d-flex justify-content-center align-items-center text-white"
                            v-for="index in players.length" :key="'round' + index">
                            Round {{ index }}
                        </div>
                    </div>
                    <div class="single-elimination-bracket d-flex flex-row position-relative"
                        @mousedown="setDown(true, $event)" @mouseup="setDown(false)"
                        @mousemove="setBracketPosition($event)">

                        <div class="bracket-round" v-for="(round, i) in players" v-bind:key="i"
                            :class="['bracket-round-' + (i + 1)]">
                            <div class="match-container" v-for="(matchItem, j) in round.games" v-bind:key="j" :class="[hasByes ? {
                                'preBye': i === 0 && players[1]['games'][matchItem.next].hasBye,
                                'noPreBye': i === 0 && !players[1]['games'][matchItem.next].hasBye,
                                'top': i === 0 && !players[1]['games'][matchItem.next].hasBye && getClassName(j, matchItem.next, 'top'),
                                'bottom': i === 0 && !players[1]['games'][matchItem.next].hasBye && getClassName(j, matchItem.next, 'bottom'),
                                'isBye': matchItem.hasBye,
                            } : '']" :style="[
                                i === 0 && hasByes ? getMargin(j, matchItem.next) : '',
                            ]">
                                <span class="match-number pr-1">
                                    {{ '#' + matchItem.seed }}
                                </span>

                                <div class="match">
                                    <div class="player-container participant-container" :class="{
                                        'tournament-winner': winnerId && matchItem.match.participant.id === winnerId && i === players.length - 1 && !isThirdPlaceEnabled,
                                        'first-place-winner': winnerId && matchItem.match.participant.id === winnerId && i === players.length - 1 && isThirdPlaceEnabled,
                                        'second-place-winner': secondPlaceId && secondPlaceId === matchItem.match.participant.id && i === players.length - 1
                                    }">
                                        <div class="player" v-bind:key="matchItem.match.participant.id"
                                            @mouseleave="hoverItem(null)"
                                            @mouseover="hoverItem(matchItem.match.participant.id)" :class="{
                                                'hover-item': hover_item === matchItem.match.participant.id && matchItem.match.participant.id,
                                                'tournament-winner': winnerId && matchItem.match.participant.id === winnerId && i === players.length - 1 && !isThirdPlaceEnabled,
                                                'first-place-winner': winnerId && matchItem.match.participant.id === winnerId && i === players.length - 1 && isThirdPlaceEnabled,
                                                'second-place-winner': secondPlaceId && secondPlaceId === matchItem.match.participant.id && i === players.length - 1,
                                                'winner': matchItem.match.participant.winner,
                                                'lost': matchItem.match.competitor.winner,

                                            }">
                                            <div class="player-avatar text-center align-content-center">
                                                <div class="avatar-container">
                                                    <img alt="" width="100%" height="100%"
                                                        :src="avatar_dir + matchItem.match.participant.avatar"
                                                        @error="replaceImage">
                                                </div>

                                            </div>
                                            <div class="player-name px-2">
                                                {{ matchItem.match.participant.name }}
                                            </div>
                                        </div>
                                        <div class="player-score" @mouseleave="hoverItem(null)"
                                            @mouseover="hoverItem(matchItem.match.participant.id)" :class="{
                                                'winner': matchItem.match.participant.winner,
                                                'hover-item': hover_item === matchItem.match.participant.id && matchItem.match.participant.id,
                                            }">
                                            <input type="number" min="0" v-model="matchItem.match.participant.score"
                                                maxlength="2" readonly>
                                        </div>
                                    </div>
                                    <div class="player-container competitor-container" :class="{
                                        'tournament-winner': winnerId && matchItem.match.competitor.id === winnerId && i === players.length - 1 && !isThirdPlaceEnabled,
                                        'first-place-winner': winnerId && matchItem.match.competitor.id === winnerId && i === players.length - 1 && isThirdPlaceEnabled,
                                        'second-place-winner': secondPlaceId && secondPlaceId === matchItem.match.competitor.id && i === players.length - 1
                                    }">
                                        <div class="player" v-bind:key="matchItem.match.competitor.id"
                                            @mouseleave="hoverItem(null)"
                                            @mouseover="hoverItem(matchItem.match.competitor.id)" :class="{
                                                'winner': matchItem.match.competitor.winner,
                                                'hover-item': hover_item === matchItem.match.competitor.id && matchItem.match.competitor.id,
                                                'tournament-winner': winnerId && matchItem.match.competitor.id === winnerId && i === players.length - 1 && !isThirdPlaceEnabled,
                                                'first-place-winner': winnerId && matchItem.match.competitor.id === winnerId && i === players.length - 1 && isThirdPlaceEnabled,
                                                'second-place-winner': secondPlaceId && secondPlaceId === matchItem.match.competitor.id && i === players.length - 1,
                                                'lost': matchItem.match.participant.winner,
                                            }">
                                            <div class="player-avatar text-center align-content-center" :class="{
                                                'winner': matchItem.match.competitor.winner,
                                            }">
                                                <div class="avatar-container">
                                                    <img alt="" width="100%" height="100%"
                                                        :src="avatar_dir + matchItem.match.competitor.avatar"
                                                        @error="replaceImage">
                                                </div>

                                            </div>
                                            <div class="player-name px-2">
                                                {{ matchItem.match.competitor.name }}
                                            </div>
                                        </div>
                                        <div class="player-score" @mouseleave="hoverItem(null)"
                                            @mouseover="hoverItem(matchItem.match.competitor.id)" :class="{
                                                'winner': matchItem.match.competitor.winner,
                                                'hover-item': hover_item === matchItem.match.competitor.id && matchItem.match.competitor.id,
                                            }">
                                            <input type="number" min="0" v-model="matchItem.match.competitor.score"
                                                maxlength="2" readonly>
                                        </div>
                                    </div>
                                </div>
                                <!-- third match goes here if enabled -->
                                <div class="third-place-match-container p-2"
                                    v-if="(i === players.length - 1) && isThirdPlaceEnabled">
                                    <div class="third-match-content">
                                        <h6 class="text-grey mb-2">Match de classement:</h6>
                                        <div class="third-place-match">
                                            <div class="player-container participant-container"
                                                :class="{ 'third-place-winner': thirdPlaceId && thirdPlaceId === thirdPlaceMatch.match.participant.id && thirdPlaceMatch.status !== 'pending' }">
                                                <div class="player" @mouseleave="hoverItem(null)"
                                                    @mouseover="hoverItem(thirdPlaceMatch.match.participant.id)" :class="{
                                                        'hover-item': hover_item === thirdPlaceMatch.match.participant.id && thirdPlaceMatch.match.participant.id,
                                                        'third-place-winner': thirdPlaceId && thirdPlaceId === thirdPlaceMatch.match.participant.id && thirdPlaceMatch.status !== 'pending',
                                                        'winner': matchItem.match.participant.winner,
                                                        'lost': matchItem.match.competitor.winner,

                                                    }">
                                                    <div class="player-avatar text-center align-content-center" :class="{
                                                        'winner': matchItem.match.participant.winner,
                                                    }">
                                                        <div class="avatar-container">
                                                            <img alt="" width="100%" height="100%"
                                                                :src="avatar_dir + thirdPlaceMatch.match.participant.avatar"
                                                                @error="replaceImage">
                                                        </div>
                                                    </div>
                                                    <div class="player-name px-2">
                                                        {{ thirdPlaceMatch.match.participant.name }}
                                                    </div>
                                                </div>
                                                <div class="player-score" @mouseleave="hoverItem(null)"
                                                    @mouseover="hoverItem(thirdPlaceMatch.match.participant.id)"
                                                    :class="{
                                                        'winner': thirdPlaceMatch.match.participant.winner, 'hover-item': hover_item === thirdPlaceMatch.match.participant.id && thirdPlaceMatch.match.participant.id
                                                    }">
                                                    <input type="number" min="0"
                                                        v-model="thirdPlaceMatch.match.participant.score" maxlength="2"
                                                        readonly>
                                                </div>
                                            </div>
                                            <div class="player-container competitor-container"
                                                :class="{ 'third-place-winner': thirdPlaceId && thirdPlaceId === thirdPlaceMatch.match.competitor.id && thirdPlaceMatch.status !== 'pending' }">
                                                <div class="player" @mouseleave="hoverItem(null)"
                                                    @mouseover="hoverItem(thirdPlaceMatch.match.competitor.id)" :class="{
                                                        'hover-item': hover_item === thirdPlaceMatch.match.competitor.id && thirdPlaceMatch.match.competitor.id,
                                                        'third-place-winner': thirdPlaceId && thirdPlaceId === thirdPlaceMatch.match.competitor.id && thirdPlaceMatch.status !== 'pending'
                                                    }">
                                                    <div class="player-avatar text-center align-content-center" :class="{
                                                        'winner': matchItem.match.competitor.winner,
                                                    }">
                                                        <div class="avatar-container">
                                                            <img alt="" width="100%" height="100%"
                                                                :src="avatar_dir + thirdPlaceMatch.match.competitor.avatar"
                                                                @error="replaceImage">
                                                        </div>
                                                    </div>
                                                    <div class="player-name px-2">
                                                        {{ thirdPlaceMatch.match.competitor.name }}
                                                    </div>
                                                </div>
                                                <div class="player-score" @mouseleave="hoverItem(null)"
                                                    @mouseover="hoverItem(thirdPlaceMatch.match.competitor.id)"
                                                    :class="{ 'winner': thirdPlaceMatch.match.competitor.winner, 'hover-item': hover_item === thirdPlaceMatch.match.competitor.id && thirdPlaceMatch.match.competitor.id }">
                                                    <input type="number" min="0"
                                                        v-model="thirdPlaceMatch.match.competitor.score" maxlength="2"
                                                        readonly>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import moment from "moment";
export default {
    name: "LgameEliminationBracket",

    props: {
        bracket_size: Number,
        /*players: Array,*/
        tournamentId: Number,
        isPlayOff: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            //size: 0,
            hover_item: null,
            mousePosition: {},
            lastMousePosition: {},
            isDown: false,
            offset: [0, 0],
            bracketPosition: [0, 0],
            bracket: [],
            byes: {
                upper: [],
                lower: [],
            },
            withByes: false,
            nbrOfByes: 0,
            rounds: 0,
            numberOfMatches: 0,
            canReportScore: false,
        }
    },
    computed: {
        isLoaded: {
            get() {
                return !!this.$store.state.currentTournament.tournamentItem;
            }
        },
        size: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.currentTournament.tournamentItem.bracket.size;
                }
                return null;
            }
        },
        hasByes: {
            get() {
                if (this.isLoaded && this.$store.state.currentTournament.tournamentItem.bracket.settings) {
                    return this.$store.state.currentTournament.tournamentItem.bracket.settings.hasByes
                        ? this.$store.state.currentTournament.tournamentItem.bracket.settings.hasByes === true
                        : this.$store.state.currentTournament.tournamentItem.bracket.settings.play_off?.hasByes === true;
                }
                return false;
            }
        },
        players: {
            get() {
                if (this.isLoaded && this.$store.state.currentTournament.tournamentItem.bracket.structure) {
                    return this.isPlayOff
                        ? this.isThirdPlaceEnabled
                            ? this.$store.state.currentTournament.tournamentItem.bracket.structure.play_off.bracket
                            : this.$store.state.currentTournament.tournamentItem.bracket.structure.play_off
                        : this.isThirdPlaceEnabled
                            ? this.$store.state.currentTournament.tournamentItem.bracket.structure.bracket
                            : this.$store.state.currentTournament.tournamentItem.bracket.structure;
                }
                return null;

            },
        },
        participantType: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.currentTournament.participantType;
                }
                return null;
            }
        },
        avatar_dir: {
            get() {
                if (this.isLoaded) {
                    return this.participantType === 'player'
                        ? 'uploads/players/avatars/'
                        : 'uploads/teams/avatars/';
                }
                return null;
            }
        },
        roundHeight: {
            get() {
                if (this.isLoaded) {
                    return this.players[0].games.length * 100;
                }
                return null;
            }
        },

        tournament_ended: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.currentTournament.tournamentItem.end_date
                        ? moment().isAfter(moment(this.$store.state.currentTournament.tournamentItem.end_date, 'DD-MM-YYYY'))
                        : true;
                }
                return null;
            }
        },
        winnerId: {
            get() {
                if (this.isLoaded) {
                    const lastRound = this.players[this.players.length - 1]['games'];
                    const lastGame = lastRound[lastRound.length - 1]['match'];
                    return lastGame.competitor.winner
                        ? lastGame.competitor.id
                        : lastGame.participant.winner ? lastGame.participant.id : null;
                }
                return null;

            }
        },
        secondPlaceId: {
            get() {
                if (this.isThirdPlaceEnabled) {
                    const lastRound = this.players[this.players.length - 1]['games'];
                    const lastGame = lastRound[lastRound.length - 1]['match'];
                    return lastGame.competitor.winner
                        ? lastGame.participant.id
                        : lastGame.participant.winner ? lastGame.competitor.id : null;
                }
                return null;
            }
        },
        thirdPlaceId: {
            get() {
                if (this.isThirdPlaceEnabled) {
                    return this.thirdPlaceMatch.match.participant.winner
                        ? this.thirdPlaceMatch.match.participant.id
                        : this.thirdPlaceMatch.match.competitor.winner ? this.thirdPlaceMatch.match.competitor.id : null;
                }
                return null;
            }
        },
        isThirdPlaceEnabled: {
            get() {
                return this.$store.state.currentTournament.tournamentItem.bracket.third_place === 'enabled';
            }
        },
        thirdPlaceMatch: {
            get() {
                if (this.isThirdPlaceEnabled) {
                    return this.isPlayOff
                        ? this.$store.state.currentTournament.tournamentItem.bracket.structure.play_off.third_place
                        : this.$store.state.currentTournament.tournamentItem.bracket.structure.third_place
                }
                return null;
            }
        }
        /*isPlayOff:{
            get(){
                return this.$store.state.currentTournament.tournamentItem.bracket.settings
                    ? this.$store.state.currentTournament.tournamentItem.bracket.settings.play_off.activated
                    : false;
            }
        }*/
    },
    methods: {
        hoverItem(item) {
            this.hover_item = item;
            /*if(item === this.currentUser.player.id || this.currentUser.organizer){
                this.canReportScore = true;
            }*/
        },
        setDown(value, event) {
            this.isDown = value;
            if (value === true) {
                this.setOffset(event);
                this.$refs.bracketcontainer.style.cursor = "grabbing";
            } else {
                this.$refs.bracketcontainer.style.cursor = "grab";
            }
        },
        setOffset(event) {
            this.offset = [
                this.$refs.bracketcontainer.offsetLeft - event.clientX,
                this.$refs.bracketcontainer.offsetTop - event.clientY,
            ];
        },
        setBracketPosition(event) {
            let limit = false;
            this.mousePosition = {
                x: event.clientX,
                y: event.clientY
            };
            limit = Math.abs(this.$refs.bracketcontainer.offsetLeft) >= (this.$refs.bracketcontainer.offsetWidth / 2);
            if (limit) {
                if (this.lastMousePosition.x > this.mousePosition.x || this.lastMousePosition.x < this.mousePosition.x) limit = false;
            }
            if (this.isDown && !limit) {
                this.$refs.bracketcontainer.style.left = (this.offset[0] + this.mousePosition.x) + "px";
                this.$refs.bracketcontainer.style.top = (this.offset[1] + this.mousePosition.y) + "px";
                this.lastMousePosition = this.mousePosition;
            }
        },
        replaceImage(e) {
            e.target.src = require('@/assets/avatar-error-placeholder.png');
        },
        getClassName(matchIndex, next, className) {
            if (className === 'top' && this.players[0]['games'][matchIndex + 1]) {
                return next === this.players[0]['games'][matchIndex + 1].next;
            }
            if (className === 'bottom' && this.players[0]['games'][matchIndex - 1]) {
                return next === this.players[0]['games'][matchIndex - 1].next;
            }
            return false;
        },
        getMargin(matchIndex, next) {
            if (matchIndex < this.players[0]['games'].length) {
                if (next === 0) {
                    return {
                        marginTop: '50px',
                        marginBottom: '50px',
                    }
                } else {
                    if (this.players[0]['games'][matchIndex].next === this.players[0]['games'][matchIndex + 1]?.next
                        && !this.players[1]['games'][next].hasBye) {
                        return {
                            position: 'absolute',
                            top: `${next * 200}px`,
                        }
                    }
                    if (this.players[0]['games'][matchIndex].next === this.players[0]['games'][matchIndex - 1].next
                        && !this.players[1]['games'][next].hasBye) {
                        return {
                            position: 'absolute',
                            top: `${(next * 200) + 100}px`,
                        }
                    }
                    return {
                        position: 'absolute',
                        top: `${(next * 200) + 50}px`,
                    }
                }
            } else {
                if (this.players[0]['games'].length === 1) {
                    return {
                        marginTop: '50px',
                        marginBottom: '50px',
                    }
                }
                if (this.players[1]['games'][next].hasBye) {
                    return {
                        position: 'absolute',
                        top: `${(next * 200) + 50}px`,
                    }
                }
            }
        }
    },
    created() {
        // const bracketId = this.$store.state.currentTournament.tournamentItem.bracket.id;
        //this.$store.dispatch('bracket/getBracketStructure', bracketId);
        //this.roundHeight = this.players[0].games.length * 100;
        //this.updateScore(1,2,3,3);
        console.log('bracket created')
    },
    activated() {
        //const bracketId = this.$store.state.currentTournament.tournamentItem.bracket.id;
        //this.$store.dispatch('bracket/getBracketStructure', bracketId);
        this.canReportScore = false;
    }
}
</script>

<style scoped>
.lgame-elimination-bracket-container {
    position: relative;
    overflow: hidden;
    background-color: transparent;
}

.bracket-controls {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1;
}

.btn-bracket-control {
    height: 24px;
    width: 24px;
    background-color: #010D11;
    color: #910B29;
    outline: none;
    border: 0;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}

.bracket-container-wrapper {
    position: relative;
    width: 100%;
    height: 580px;

    /*min-height: 100vh;*/
    padding: 0;
    /*2rem;*/
    overflow: auto;
    overflow-x: scroll;
}

.bracket-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    justify-content: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.round-number-container {
    width: fit-content;
    width: -moz-fit-content;
}

.round-number {
    width: 260px;
    background-color: #0A0B0C;
    height: 32px;
    margin-inline: 2.6rem;
    position: relative;
    font-family: "Roboto Mono", sans-serif;
}

.bracket-container:hover {
    cursor: grab;
}

.bracket-round .round-number {
    color: white;
    width: 100%;
    text-align: center;
}

.bracket-round {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 260px;
    height: fit-content;
    margin-inline: 2.6rem;
    background-color: transparent;
}

.bracket-round:last-child {
    /* background-color: green;*/
}

.match-container {
    position: relative;
    width: 260px;
    height: 100px;
    padding: 0.2rem;
    /*margin-top: 0.2rem;
        margin-bottom:  0.2rem;*/
    display: flex;
    align-items: center;
}

.match-number {
    color: white;
    font-size: 16px;
    font-family: 'Roboto Mono', sans-serif;
}

.match {
    width: 100%;
    height: 5rem;
    /* equivalent to 80px */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.third-place-match-container {
    position: absolute;
    left: 10px;
    width: 100%;
    height: fit-content;
    top: 64px;
    margin-top: 46px;
}

.third-place-match-container .player-container:first-child {
    margin-bottom: 4px;
}

.bracket-round:last-child .match-container:last-child {
    /* slect the last match in the last round => final match*/
    /*background-color: blue;*/
}

.player-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
}

.player {
    position: relative;
    height: 36px;
    width: 180px;
    background-color: #0A0B0C;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.player:hover {
    cursor: pointer;
}

.match-container .player-container:last-child {
    /*margin-top: 2px;*/
}

.match-container .player-container:last-child .player {
    /*clip-path: polygon(0 0, calc(100% - 20px) 0, 100% calc(100% - 8px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 10px));*/

}

.player-avatar {
    width: 36px;
    height: 36px;
    background-color: #0A0B0C;
}

.avatar-container {
    width: 28px;
    height: 28px;
    margin-left: 4px;
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
}

.player-name {
    text-transform: capitalize;
    color: white;
    font-weight: 500;
    text-align: center;
    font-size: 14pt;
    margin-left: 2px;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 130px;
    width: 68%;
    white-space: nowrap;
}

.player-score {
    height: 36px;
    width: 42px;
    background-color: #0A0B0C;
    color: white;
}



input {
    height: 100%;
    width: 100%;
    border: 0;
    outline: none;
    text-align: center;
    background-color: transparent;
    color: white;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-family: "Roboto Mono", sans-serif;
}

.player-score.winner input,
.player.winner {
    color: #FFBD30 !important;
    font-family: "Roboto Mono", sans-serif;
    font-weight: 500;
}

.player.winner::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: #FFBD30;
}

.player.lost .player-name,
.player.lost+.player-score input {
    color: rgba(255, 255, 255, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.bracket-round .match-container:last-child {
    margin-bottom: 0;

}

.match-container .player-container:last-child .player .player-avatar {}

.match-container .player-container:last-child .player+.player-score:last-child {}

.bracket-round:nth-child(n+2) {
    /*background-color: white;*/
    /* selecting the n+1 round n>0 */
    justify-content: flex-start;
}

.bracket-round:nth-child(n+2) .match-container {
    margin-top: calc(50px);
    margin-bottom: calc(50px);
}

.bracket-round:first-child .match-container:nth-child(2n):not(.preBye):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(50px + 0.2rem);
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round:first-child .match-container:nth-child(2n+1):not(.preBye):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(50px + 0.2rem);
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round:nth-child(n+2) .match-container:before {
    content: "";
    position: absolute;
    left: -2.6rem;
    width: 2.6rem;
    border-top: 0.15rem solid dimgrey;
}

.bracket-round:last-child .match-container:last-child:after {
    border: 0;
}

/** will do it the hard way*/
/*new style for byes */
/*
    .bracket-round:first-child .match-container:not(.preBye):nth-child(2n):after{
        content: "";
        position: absolute;
        top: 50%;
        bottom: 0 !important;
        right: -2.6rem;
        width: 2.6rem;
        height: calc(50px + 0.4rem);
        background-color: transparent;
        border-top: 0.15rem solid dimgrey;
        border-bottom: unset !important;
        border-right:0.15rem solid dimgrey;
        transform: translateY(0) !important;
    }
    .bracket-round:first-child .match-container:not(.preBye):nth-child(2n+1):after{
        content: "";
        position: absolute;
        bottom: 50%;
        top: 0 !important;
        right: -2.6rem;
        width: 2.6rem;
        height: calc(50px + 0.4rem);
        background-color: transparent;
        border-bottom: 0.15rem solid dimgrey;
        border-top: unset !important;
        border-right: 0.15rem solid dimgrey;
        transform: translateY(0) !important;
    }*/
/*new edits for perfect case */
.bracket-round:first-child .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(50px + 0.2rem);
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round:first-child .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(50px + 0.2rem);
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

/*end new edits */


.bracket-round:first-child .match-container.noPreBye.top:after {
    content: "";
    position: absolute;
    top: 50% !important;
    bottom: 0 !important;
    right: -2.6rem !important;
    width: 2.6rem !important;
    height: calc(50px + 0.4rem);
    background-color: transparent;
    border-top: 0.15rem solid dimgrey !important;
    border-bottom: unset !important;
    border-right: 0.15rem solid dimgrey !important;
    transform: translateY(0) !important;
}

.bracket-round:first-child .match-container.noPreBye.bottom:after {
    content: "";
    position: absolute;
    bottom: 50% !important;
    top: 0 !important;
    right: -2.6rem !important;
    width: 2.6rem !important;
    height: calc(50px + 0.4rem);
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey !important;
    border-top: unset !important;
    border-right: 0.15rem solid dimgrey !important;
    transform: translateY(0) !important;
}

.match-container.isBye:before {
    border: none !important;
}

.match-container.preBye:after {
    content: "";
    position: absolute;
    bottom: 50% !important;
    right: -5.2rem !important;
    width: 5.2rem !important;
    height: calc(50px + 0.4rem);
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-top: unset !important;
    border-right: unset !important;
    top: 0 !important;
    transform: translateY(2px);
}

/*end style for byes*/

.bracket-round-1 .match-container:nth-child(2n):not(.preBye):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(50px + 0.4rem);
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-1 .match-container:nth-child(2n+1):not(.preBye):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(40px + 0.4rem);
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-2 .match-container {
    margin-top: calc(50px + 0.2rem);
    margin-bottom: calc(50px + 0.2rem);
}

.bracket-round-2 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(2*50px + 0.2rem) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-2 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(2*50px + 0.2rem) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-3 .match-container {
    margin-top: calc(3*50px) !important;
    margin-bottom: calc(3*50px) !important;
}

.bracket-round-3 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(3*100px + 0.2rem) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-3 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(3*100px + 0.2rem) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-4 .match-container {
    margin-top: calc(7*50px) !important;
    margin-bottom: calc(7*50px) !important;
}

.bracket-round-4 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(7*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-4 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(7*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-5 .match-container {
    margin-top: calc(15*50px) !important;
    margin-bottom: calc(15*50px) !important;
}

.bracket-round-5 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(15*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-5 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(15*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-6 .match-container {
    margin-top: calc(31*50px) !important;
    margin-bottom: calc(31*50px) !important;
}

.bracket-round-6 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(31*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-6 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(31*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-7 .match-container {
    margin-top: calc(63*50px) !important;
    margin-bottom: calc(63*50px) !important;
}

.bracket-round-7 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(63*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-7 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(63*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-8 .match-container {
    margin-top: calc(127*50px) !important;
    margin-bottom: calc(127*50px) !important;
}

.bracket-round-8 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(127*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-8 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(127*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

.bracket-round-9 .match-container {
    margin-top: calc(254*50px) !important;
    margin-bottom: calc(127*50px) !important;
}

.bracket-round-9 .match-container:nth-child(2n):after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(254*50px + 100px) !important;
    background-color: transparent;
    border-bottom: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(2px);
}

.bracket-round-9 .match-container:nth-child(2n+1):after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2.6rem;
    width: 2.6rem;
    height: calc(254*50px + 100px) !important;
    background-color: transparent;
    border-top: 0.15rem solid dimgrey;
    border-right: 0.15rem solid dimgrey;
    transform: translateY(-2px);
}

/** change the background color when hovering an element of the bracket*/
.hover-item {
    background-color: var(--main-color) !important;
}


.player.tournament-winner {
    background-color: rgba(60, 153, 7, 0.8) !important;
    color: white;
}

.tournament-winner .player-name {
    color: white;
}

.tournament-winner+.player-score {
    background-color: rgba(60, 153, 7, 0.8) !important;
}

.player-container.tournament-winner:after {
    content: " ";
    background-image: var(--trophy-winner);
    background-size: cover;
    position: absolute;
    color: white;
    height: 32px;
    width: 32px;
    object-fit: cover;
    right: -32px;
}

.player.second-place-winner:not(.tournament-winner) {}

.second-place-winner:not(.tournament-winner)+.player-score {}

.player.first-place-winner:after {
    content: " ";
    background-image: var(--first-place);
    background-size: cover;
    position: absolute;
    color: white;
    height: 32px;
    width: 32px;
    object-fit: cover;
    right: 0;
    top: 0;
}

.player.second-place-winner:after {
    content: " ";
    background-image: var(--second-place);
    background-size: cover;
    position: absolute;
    color: white;
    height: 32px;
    width: 32px;
    object-fit: cover;
    right: 0;
    top: 0;
}

.player.third-place-winner:after {
    content: " ";
    background-image: var(--third-place);
    background-size: cover;
    position: absolute;
    color: white;
    height: 32px;
    width: 32px;
    object-fit: cover;
    right: 0;
    top: 0;
}

@media screen and (max-width: 768px) {
    .bracket-container-wrapper {
        height: 70vh;
    }
}
</style>
