<template>
    <div class="faq-item-wrapper mb-3 p-4 w-100" @click="expanded = !expanded">
        <div class="faq-item-container">
            <div class="question-container d-flex justify-content-between align-items-center w-100">
                <h5 class="question text-left m-0">{{ faqItem.question }}</h5>
                <div class="arrow-container" :class="{'expanded': expanded}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 44 23">
                        <path d="M22,31h0L0,8H7.652L22,23,36.348,8H44L22,31Z" transform="translate(0 -8)" fill="#004FFE"/>
                    </svg>

                </div>
            </div>
            <transition name="slide-fade">
                <div v-if="expanded">
                    <p class="answer px-2 text-justify mt-2" v-html="faqItem.answer">
                     
                    </p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "faqItem",
        props:["faqItem"],
        data(){
            return {
                expanded: false,
            }
        }
    }
</script>

<style lang="css" scoped>
    .slide-fade-enter-active {
        transition: all .2s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.15, 0.3, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    {
        transform: translateY(-10px);
        opacity: 0;
    }
    .faq-item-wrapper{
        border: 2px solid #004FFE;
        cursor: pointer;
        background-color: var(--bg-color);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    }
    .question{
        color: #3A86FB;
        font-weight: 500;
        font-family: "Roboto" !important;
    }
    .arrow-container{
        transition: all .3s cubic-bezier(1.0, 0.15, 0.3, 1.0);
    }
    .expanded{
        transform: rotate(-180deg);
    }
    .answer{
        color: white;
        font-weight: 400;
        font-family: "Roboto" !important;
    }

</style>