<template>
    <div class="mt-2" id="faq">
        <h2 class="faq-title text-left mb-2">3NDK CHI SOU2AL ?</h2>
        <div class="py-2">
            <FaqItem v-for="(faqItem, i) in faqList" :key="i" :faqItem="faqItem" />
        </div>

    </div>
</template>

<script>
    import FaqItem from './FaqItem.vue';
    export default {
        name: "FaqList",
        components:{
            FaqItem
        },
        data(){
            return {
                faqList:[
                    {
                        question: "Comment puis-je créer un compte sur Lgame.gg ?",
                        answer: `L’inscription des équipes au tournoi se fait en cinq étapes :<br>
                       1 - Chaque membre de l’équipe doit créer un compte sur <a href="lgame.gg" target="_blank">Lgame.gg</a>, en suivant les <a href="https://youtu.be/15q-SAFSBVI" target="_blank">instructions suivantes</a> .<br>
                       2 - Le capitaine de l’équipe doit ensuite créer l’équipe sur <a href="lgame.gg" target="_blank">Lgame.gg</a>, en suivant la <a href="https://youtu.be/O-0hvV8WwUY" target="_blank">procédure décrite</a>.<br>
                       3 - Le capitaine de l’équipe procède à l’inscription de l’équipe au tournoi.<br>
                       4 - Enfin, il faut rejoindre le serveur <a href="https://discord.gg/5NdrRBpSVR" target="_blank">Discord</a> du tournoi.`
                    },
                    {
                        question: "Où se déroulera la finale du tournoi ?",
                        answer: "Les qualifications se dérouleront en ligne et la grande finale aura lieu à Casablabnca le 18 mai."
                    },
                    {
                        question: "Quels bénéfices offre la carte Code 30 et Sayidati ?",
                        answer: `Si vous n'êtes pas déjà client du CIH, nous vous suggérons d'explorer les avantages de la banque et de ses services en cliquant sur le <a href="https://avantagescartescih.ma/" target="_blank">lien</a> suivant. De plus, vous avez la possibilité de découvrir les avantages des cartes <a href="https://www.code30.ma/" target="_blank">CIH Code 30</a> et <a href="https://www.cihbank.ma/MDM/nos-offres/cartes-bancaires/cartes-multiservices/carte-saydati" target="_blank">CIH Sayidati</a>, spécialement conçues pour les gamers.`
                    },
                    {
                       question: "Puis-je ouvrir un compte CIH en ligne sans me déplacer à la banque ?",
                        answer: `Tout à fait ! Vous pouvez ouvrir un compte CIH en ligne sans vous rendre à la banque, grâce à <a href='https://www.e-cihbank.ma/' target='_blank'>E-CIHBank</a>. Cette plateforme vous permet d’ouvrir votre compte bancaire depuis le confort de votre domicile en seulement 5 étapes simples, en utilisant uniquement votre selfie ;).`
                    },
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
    .faq-title{
        color: var(--main-color);
        font-family: "monument_extendedultrabold" !important;
        font-weight: 600;
        position: relative;
        z-index: 2;
    }
    .faq-title::after{
        content:"/////////////////////////";
        font-family: "Roboto";
        font-size:18px;
        color: white;
        position: absolute;
        z-index: 1;
        position:absolute;
        bottom:-16px;
        left: 0;
    }
    @media screen and (max-width: 768px) {
        .faq-title {
            font-size: 18pt;
        }
    }
</style>