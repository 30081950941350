<template>
    <div class="d-flex justify-content-center align-items-center">
        <div class="mr-4">
            <a class="icon-container" href="https://www.facebook.com/cihbank/" target="_blank">
                <img :src="require('@/assets/rs_images/facebook.svg')" height="18px" />
            </a>
        </div>
        <div class="mr-4">
            <a class="icon-container" href="https://twitter.com/cihbank" target="_blank">
                <img :src="require('@/assets/rs_images/x-logo.svg')" height="18px" />
            </a>
        </div>
        <div class="mr-4">
            <a class="icon-container" href="https://www.instagram.com/cihbankma/" target="_blank">
                <img :src="require('@/assets/rs_images/Insta.svg')" height="18px" />
            </a>
        </div>
        <div class="mr-4">
            <a class="icon-container" href="https://www.youtube.com/channel/UC2sZyU1dfxLlMIBGGvquJWg" target="_blank">
                <img :src="require('@/assets/rs_images/Youtube.svg')" height="18px" />
            </a>
        </div>
        <div>
            <a class="icon-container" href="https://www.linkedin.com/company/cihbank/" target="_blank">
                <img :src="require('@/assets/rs_images/linkedin-icon.svg')" height="18px" />
            </a>
        </div>
    </div>
</template>
<script>
export default{
    name: "RSLinks",
}
</script>
<style scoped>
.icon-container:hover img{
    transform: translateY(-2px);
}
</style>