<template>
    <div class="wrapper w-100">
        <div class="bg-red">

        </div>
        <div class="footer-powered-by">
            <div class="img-container px-5 py-1">
                <a @click="goToLgame">
                    <img :src="require('@/assets/powered-by.png')" height="18px" />
                </a>
            </div>
        </div>

        <!--  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="78"
            viewBox="0 0 1920 60">
            <defs>
                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="red" stop-opacity="0" />
                    <stop offset="1" stop-color="#dc0921" />
                </linearGradient>
                <clipPath id="clip-path">
                    <rect id="Rectangle_566" data-name="Rectangle 566" width="1920" height="78" fill="#fff"
                        stroke="#707070" stroke-width="1" />
                </clipPath>
                <filter id="Union_1" x="-68.682" y="-0.238" width="2264.274" height="499.477"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="-3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="clip-path-2">
                    <path id="Path_6" data-name="Path 6"
                        d="M0,3.226V27.758l3.757-1.7,2.1-.948,3.208-1.449,3.087-1.4,2.77-1.248,2.96-1.337,5.975-2.7V11.666l-2.321,1.052L17.88,14.366l-.111.052L14.923,15.7l-2.77,1.252L9.065,18.346,5.857,19.8V1.088A27.142,27.142,0,0,0,0,3.226"
                        transform="translate(0 -1.088)" fill="none" />
                </clipPath>
                <linearGradient id="linear-gradient-2" x1="0.166" y1="1.147" x2="0.171" y2="1.147"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#dc0b1b" />
                    <stop offset="1" stop-color="#dc0921" />
                </linearGradient>
                <clipPath id="clip-path-3">
                    <rect id="Rectangle_3" data-name="Rectangle 3" width="123.872" height="36.644" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_161" data-name="Group 161" transform="translate(0 -9371)">
                <rect id="Rectangle_564" data-name="Rectangle 564" width="613.992" height="12.963"
                    transform="translate(1306.008 9409.613)" fill="url(#linear-gradient)" />
                <g id="Group_160" data-name="Group 160" transform="translate(0 9371)">
                    <g id="Mask_Group_10" data-name="Mask Group 10" clip-path="url(#clip-path)">
                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_1)">
                            <path id="Union_1-2" data-name="Union 1"
                                d="M0,2246.274V1357.393H18.518V0H427.772V1336.462l53.7,47.873v478.517l-35.185,31.364v352.059Z"
                                transform="translate(-59.68 493.24) rotate(-90)" fill="#031720" />
                        </g>
                    </g>
                    <g id="Group_138" data-name="Group 138" transform="translate(1618.952 26.772)" class="lgame-logo" @click="goToLgame">
                        <path id="Path_1" data-name="Path 1" d="M188.88,43.7h5.375v4.927H183.447V28.972h5.431Z"
                            transform="translate(-149.417 -23.598)" fill="#fff" />
                        <path id="Path_2" data-name="Path 2"
                            d="M247.3,70.134v-9.21l6.075-5.543h10.165V71.453l-5.351,4.868-5.46.056V71.506l5.375-.056V66.691l-3.751,3.443Zm5.431-9.826v4.9H258.1v-4.9Z"
                            transform="translate(-201.421 -45.108)" fill="#fff" />
                        <path id="Path_3" data-name="Path 3"
                            d="M342.976,70.134v-9.21l6.1-5.543h10.134V70.134h-5.431V66.718l-3.752,3.416Zm5.431-9.826v4.9h5.375v-4.9Z"
                            transform="translate(-279.352 -45.108)" fill="#fff" />
                        <path id="Path_4" data-name="Path 4"
                            d="M444.1,55.381v3.443l3.778-3.443H453.9v3.443l3.778-3.443h6.019V70.134h-5.429V60.308H453.9v9.826h-5.426V60.308H444.1v9.826h-5.431V55.381Z"
                            transform="translate(-357.297 -45.108)" fill="#fff" />
                        <path id="Path_5" data-name="Path 5"
                            d="M585.646,60.305h5.375v4.9h-5.375Zm.641-4.927-6.075,5.514v5.824l3.752,3.416h12.484V65.2h-3.691l3.7-3.443V55.378Z"
                            transform="translate(-472.58 -45.105)" fill="#fff" />
                        <g id="Group_2" data-name="Group 2" transform="translate(0 0.202)">
                            <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path-2)">
                                <rect id="Rectangle_2" data-name="Rectangle 2" width="35.728" height="35.728"
                                    transform="translate(-13.335 13.334) rotate(-44.999)"
                                    fill="url(#linear-gradient-2)" />
                            </g>
                        </g>
                        <g id="Group_4" data-name="Group 4">
                            <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path-3)">
                                <path id="Path_7" data-name="Path 7"
                                    d="M23.858,99.185v12.958l-11.73,5.287-5.817-2.646L0,111.92v-1.964l3.747-1.694,2.1-.948.01,0v1.959l.449.2,5.822,2.645,5.752-2.589v-2.3l-2.957,1.334-2.77,1.252v-5.343l2.77-1.252,2.957-1.333Z"
                                    transform="translate(0 -80.786)" fill="#fff" />
                                <path id="Path_8" data-name="Path 8"
                                    d="M63.661,2.35V8.481l-1.5.678-4.482,2.027V5.554c-.069,0-.126,0-.192,0a5.22,5.22,0,0,0-2.765.767v6.2l-.221.1-2.549,1.149-3.087,1.4V.2A27.968,27.968,0,0,1,51.956,0c.111,0,.227,0,.338,0,.824,0,1.632.034,2.432.1a28.69,28.69,0,0,1,2.957.4A27.529,27.529,0,0,1,63.661,2.35"
                                    transform="translate(-39.804)" fill="#fff" />
                            </g>
                        </g>
                    </g>
                    <text id="Powered_by" data-name="Powered by" transform="translate(1377.934 51.772)" fill="#fff"
                        font-size="23" font-family="MonumentExtended-Regular, Monument">
                        <tspan x="0" y="0">Powered by</tspan>
                    </text>
                </g>
            </g>
        </svg> -->
    </div>

</template>
<script>
    export default{
        name: "PoweredByLgame",
        methods:{
            goToLgame(){
                window.location="https://lgame.gg/"
            }
        }
    }
</script>
<style>
    .wrapper{
        filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.4));
    }
    .lgame-logo:hover{
        cursor: pointer;
    }
    .footer-powered-by{
        position: relative;
        height: 16px;
        background-color: #031720;
        z-index:4;
        
    }
    .bg-red{
        background-color: red;
        position: absolute;
        right:0;
        bottom: 0;
        height: 20px;
        width: 10%;
        z-index: 1;
    }
    .img-container{
        position: absolute;
        right: 6%;
        top:-20px;
        background-color: #031720;
        clip-path: polygon(8% 0%, 92% 0%, 100% 100%, 0% 100%);
    }
</style>
