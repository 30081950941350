//this state is used when trying to access a single user
/* eslint-disable */
import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ZHD3JDUsW8yn6ZuDEGEb00lrbc21EOf2zVpxd7zelzQsNBVQxcdr4NuejLw7';
const state = {
    /**
     * userId, username, avatar, level (if it's a player)
     * organizerId
     */
    user: {},
    user_new: {
        name: '',
        userSettings: {},
    },
    userSettings: {},
    roles_array: [],
    isAuthenticated: false,
    location: {
        coordinates: {},
        degreesValue: '\u2063'
    },
    accessToken: null,
    tournaments:{
        tournaments: null,
        next_page : null,
        prev_page : null,
        current_page : null,
    },
    apiUrl: "https://lgame.gg/api/v1/"
    //apiUrl: "http://lgame.gg:8001/api/v1/"
};
const getters = {};
const actions = {
    authCheck({commit, state}){
        return axios.get(state.apiUrl+'user/auth/status')
            .then(response => {
                if(response.data.success){
                    localStorage.setItem('isAuthenticated', 'true');
                    return {
                        success: true,
                    }
                }else{
                    localStorage.setItem('isAuthenticated', 'false');
                    return{
                        success: false,
                    }
                }
            }).catch(error => {
                console.log('check auth error ')
                console.log(error)
                console.log('check auth error ')
                localStorage.setItem('isAuthenticated', 'false');
                return{
                    success: false,
                }
            })
    },
    setCurrentUser({commit, state}){
        //commit('setAccessToken', userData.accessToken);
        //axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
        //axios.get('/user/'+userData.userId+'/profile')
        return axios.get(state.apiUrl+'user/profile') // I need to check if the data about the user already exist in localstorage
            .then(response => {
                console.log(response.data.data);
                if(response.data.success){
                    commit('setCurrentUser', response.data.data);
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }

            }).catch(error => {
                console.log('error get profile ==============================');
                console.log(error.response);
                console.log('error get profile ==============================');
                console.log(error.response.status);
                if(error.response.data.message.toLowerCase() === 'unauthenticated.' || error.response.status === 401){
                    console.log('Unauthenticated user ')
                }
                return {
                    success: false,
                }
            });
    },
    setAccessToken({commit}, token){
      commit('setAccessToken', token);
    },
    registerUser({commit, state}, newUser){
        return axios.post(state.apiUrl+'user/register', {
            email: newUser.email,
            password: newUser.password,
            name: newUser.player_name,
        }).then(response => {
            if(response.data.success){
                console.log('%c==================================== register output =================================================', 'color: red');
                console.log(response);
                console.log('%c==================================== register output =================================================', 'color: red');
                //commit('setCurrentUser', response.data.user);
                //localStorage.setItem('isAuthenticated', 'true');
               /* commit('setAccessToken', response.data.access_token);
                axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access_token;
                const access_token = JSON.stringify({
                    'accessToken': response.data.access_token
                });
                localStorage.setItem('lgame_userData', access_token);
                localStorage.setItem('lgame_isAuthenticated', true);*/
            }
            return response.data;
        }).catch(error => {
            console.log(error.response);
            return error.response.data;
        });
    },
    loginUser({commit, state},user){
        /**
        * I don't need to commit anything, just trying to log the user and transfer it to the right subdomain
        * */
        return axios.post(state.apiUrl+"user/login",{
            email: user.email,
            password: user.password,
            remember_me: user.rememberMe
        }).then(response => {
           if(response.data.success){
               commit('setCurrentUser', response.data.user);
               //commit('setAccessToken', response.data.access_token);
               axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access_token;

               localStorage.setItem('isAuthenticated', 'true');
               return response.data;
           }else{
               return response.data;
           }
        }).catch(error => {
            
            return error.response.data;
        });
    },
    logOutUser({commit, state}, params){
       
        return axios.get(state.apiUrl+"user/logout")
            .then(response => {
               
                if(response.data.success){
                    if(localStorage.getItem('isAuthenticated')){
                        localStorage.setItem('isAuthenticated', false);
                    }
                    console.log('here start')
                    if(params.redirect === true){
                        window.location = (process.env.MIX_APP_URL)+'/logout';
                    }
                    commit('logOutUser');
                    return {
                        success: true,
                        data: response.data,
                    };
                }

            })
            .catch(error => {
                console.log(error.response);
                return {
                    success: false,
                }
            });
    },
    requestResetPassword({commit, state}, payload){
        return axios.post(state.apiUrl+'user/forgot-password', {
            email: payload.email,
        }).then(response => {
            console.log('========================== password reset request response =======================');
            console.log(response);
            console.log('========================== password reset request response =======================');
            if(response.data.success){
                return {
                    success: true,
                }
            }else{
                return {
                    success: false,
                }
            }
        }).catch(error => {
            console.log(error.response);
            return {
                success: false,
            }
        })
    },
    loggedResetPassword({state}, payload) {
        const currentUserId = state.user.id;
        if(currentUserId){
            return axios.post(state.apiUrl+'user/'+currentUserId+'/auth/reset-password', payload)
                .then(response => {
                    if (response.data.success) {
                        return {
                            success: true,
                        }
                    } else {
                        return {
                            success: false,
                            erorrs: response.data.error
                        }
                    }
                }).catch(error => {
                    return {
                        success: false,
                        error: error.response.data.error,
                    }

                })
        }
    },
    resetPassword({commit, state}, payload){
        return axios.post(state.apiUrl+'user/reset-password', {
            email: payload.email,
            token: payload.token,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
        }).then(response => {
            if(response.data.success){
                console.log('%c============================== reset password output ========================', 'color: red; font-weight: bold;')
                console.log(response)
                console.log('%c============================== reset password output ========================', 'color: red; font-weight: bold;')
                return response.data;
            }
        }).catch(error => {
            console.log(error.response);
            return error.response.data;
        })
    },
    setUserName({commit}, newName){
        commit('setUserName', newName);
    },
    setUserGender({commit}, gender){
        commit('setUserGender',gender);
    },
    updateUserInfos({commit, state}, payload){
        const userId = state.user.id;
        return axios.post(state.apiUrl+'user/'+userId+'/update', payload)
            .then(response => {
                if(response.data.success){
                    console.log(response.data.data);
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            }).catch(error => {
                console.log(error)
                return {
                    success: false
                }
            });
    },
    getUserSettings({commit, state}){
        const userId = state.user.id;
        return axios.get(state.apiUrl+'user/'+userId+'/settings')
            .then(response => {
                if(response.data.success){
                    commit('setUserSettings', response.data.settings);
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            }).catch(error => {
                console.log(error);
                return {
                    success: false
                }
            })
    },
    updateUserSettings({commit, state}, payload){
        const userId = state.user.id;
        console.log(payload)

        return axios.post(state.apiUrl+'user/'+userId+'/settings/update',  payload)
            .then(response => {
                if(response.data.success){
                    commit('updateUserSettings', response.data)
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            }).catch(error => {
                console.log(error);
                return {
                    success: false,
                }
            });
    },
    requestChangeEmail({commit, state}, payload){
        const userId = state.user.id;
        return axios.post(state.apiUrl+'user/'+userId+'/change-email', payload)
            .then(response => {
                if(response.data.success){
                    return {
                        success: true,
                    }
                }else{
                    let error = {};
                    if(response.data.error.email){
                        response.data.error.email.forEach(emailError => {
                            if(emailError.includes('taken.')){
                                error.emailTaken = true;
                            }
                        })
                    }
                    return {
                        success: false,
                        error: error,
                    }
                }
            }).catch(error => {
                console.log(error);
                return {
                    success: false,
                }
            });
    },
    changeEmail({commit}, payload){

    },
    setUserLocation({commit}, location){
        commit('setUserLocation', location);
        localStorage.setItem('geoLocation', JSON.stringify(location));
    },
    setTournaments({commit}, tournaments){
        commit('setTournaments', tournaments);
    },
    loadMoreTournaments({commit, state}){
        const nextPage = state.tournaments.next_page;
        return axios.get(nextPage)
            .then(response => {
                commit('addMoreTournaments', response.data);
                console.log(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    },

};
const mutations = {
    setCurrentUser(state, data){
        state.user = data;
        state.isAuthenticated = true;
        state.roles_array = state.user.roles.map(role => {
            return role.role_name;
        });
    },
    setAccessToken(state, token){
      state.accessToken = token;
      state.isAuthenticated = true;
    },
    logOutUser(state) {
        state.user = null;
        state.accessToken = null;
        state.isAuthenticated = false;
        state.roles_array = [];
        if(localStorage.getItem('isAuthenticated')){
            localStorage.setItem('isAuthenticated', false);
        }
    },
    setUserLocation(state, location){
        state.location = location;
    },
    setTournaments(state, tournaments){
        state.tournaments.tournaments = tournaments.data;
        state.tournaments.current_page = tournaments.current_page;
        state.tournaments.next_page = tournaments.next_page_url;
        state.tournaments.prev_page = tournaments.prev_page_url;
    },
    addMoreTournaments(state, tournaments){
        state.tournaments.tournaments = state.tournaments.tournaments.concat(tournaments.data);
        state.tournaments.next_page = tournaments.next_page_url;
    },
    setUserSettings(state, settings){
        state.userSettings = settings;
        state.user_new = {
            name: state.user.name,
            userSettings: Object.assign({},state.userSettings),
        };
    },
    setUserName(state, userName){
        state.user_new.name = userName;
    },
    setUserGender(state, gender){
        state.user_new.userSettings.gender = gender;
    },
    updateUserSettings(state, data){
        state.user.name = data.user.name;
        state.userSettings = data.user_settings;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
