<template>
    <div class="timeline-wrapper w-100 d-flex justify-content-center align-items-center my-5 position-relative">
        <div class="viper-container">
            <img :src="require('@/assets/viper.png')" height="580px">
        </div>
        <div class="sova-container">
            <img :src="require('@/assets/sova.webp')" height="440px">
        </div>
        <div class="timeline-container" :style="{backgroundImage: 'url('+require('@/assets/timeline-bg.png')+')'}">
            <div class="timeline-content p-2 p-md-4">
                <h3 class="timeline-title my-2">
                    BGHITI T7DER L’TOURNOI DIAL
                    <span>
                        <img :src="require('@/assets/vct.png')" height="32px" />
                    </span> F SEOUL ?
                </h3>
                <h4 class="timeline-subtitle">
                    JME3 FRE9TEK OU MERHBA BIK M3ANA !
                </h4>
                <div class="pr-md-5">
                    <SimpleSlider :items="sliderItems" />
                </div>
                <!-- <div class="timeline-items-container mt-4 p-1 mr-auto row">
                    <div class="timeline-item col col-sm-12 col-md-6 text-right">
                        <div class="item-title-container">
                            <h5 class="item-title text-left px-2 px-md-5">INSCRIPTION</h5>
                        </div>
                        <p class="timeline-item-desc text-left">
                            Si vous êtes un client du CIH, vous pouvez participer ! Il vous suffit
                            de créer votre compte Lgame.gg et rassemblez votre équipe pour
                            vous lancer dans cette aventure.
                        </p>
                        <p class="timeline-item-date text-left">
                            17 Avril - 28 Avril 2024
                        </p>
                    </div>
                    <div class="timeline-item col col-sm-12 col-md-6">
                        <div class="item-title-container px-1 px-md-5">
                            <h5 class="item-title text-left">QUALIFICATIONS</h5>
                        </div>
                        <p class="timeline-item-desc text-left">
                            Une fois votre équipe formée sur Lgame.gg, inscrivez-vous au
                            tournoi pour participer aux éliminatoires en ligne et tenter de
                            décrocher une place pour la grande finale !
                        </p>
                        <p class="timeline-item-date text-left">
                            29 April - 10 May 2024
                        </p>
                    </div>
                    <div class="timeline-item col col-sm-12 col-md-6">
                        <div class="item-title-container">
                            <h5 class="item-title text-left px-1 px-md-5">Casablanca Final</h5>
                        </div>
                        <p class="timeline-item-desc text-left">
                            Quittez votre position derrière l’écran pour vous hisser sur la grande scène de l’esport !
                            Engagez-vous dans une compétition en
                            direct sur l’une des principales plateformes esports du Maroc, captivant ainsi un public
                            nombreux et plein d’enthousiasme.
                        </p>
                        <p class="timeline-item-date text-left">
                            18 May 2024
                        </p>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</template>
<script>
import SimpleSlider from './Slider/SimpleSlider.vue'
    export default {
        name: "TimeLine",
        components:{
            SimpleSlider,
        },
        data() {
            return {
                sliderItems:[
                    {
                        title: "INSCRIPTION",
                        content: `<p class="timeline-item-desc text-left">
                           Pour participer à notre tournoi ! Il vous suffit de créer votre compte <a href="https://lgame.gg" target="_blank">Lgame.gg</a> et de constituer votre équipe pour partir à l'aventure.
                        </p>
                        <p class="timeline-item-date text-left">
                            26 Avril - 10 Mai 2024
                        </p>`,
                    },
                    {
                        title: "QUALIFICATIONS",
                        content: ` <p class="timeline-item-desc text-left">
                            Une fois votre équipe formée sur Lgame.gg, inscrivez-vous au
                            tournoi pour participer aux éliminatoires en ligne et tenter de
                            décrocher une place pour la grande finale !
                        </p>
                        <p class="timeline-item-date text-left">
                            11 Mai - 16 Mai 2024
                        </p>`,
                    },
                    {
                        title: "Casablanca Final",
                        content: ` <p class="timeline-item-desc text-left">
                            Quittez votre position derrière l’écran pour vous hisser sur la grande scène de l’esport !
                            Engagez-vous dans une compétition en
                            direct sur l’une des principales plateformes esports du Maroc, captivant ainsi un public
                            nombreux et plein d’enthousiasme.
                        </p>
                        <p class="timeline-item-date text-left">
                            18 Mai 2024
                        </p>`,
                    }
                ]
            }
        },
    }
</script>
<style lang="css" scoped>
    .viper-container{
        position: absolute;
        left: -30px;
        top: -20px;
        height: 500px;  
        z-index: 1;      
        animation: beat 4s infinite alternate-reverse;
        
    }
    .sova-container {
        position: absolute;
        right: -230px;
        bottom: -10px;
        z-index: 1;
        animation: beat 4s infinite alternate-reverse;
    }
    .timeline-container{
        min-width: 100%;
        min-height: 480px;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    .timeline-content{
        margin-left: 200px;
    }
    .timeline-title{
        color: var(--secondary-color);
        font-family: "monument_extendedregular";
        margin-left: -60px;
    }
    .timeline-subtitle{
        color: white;
        font-family: "monument_extendedregular";
    }
    .item-title-container{
        background-color: var(--secondary-color);
        width: fit-content;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
    }
    .item-title{
        font-family: "monument_extendedregular";
        text-transform: uppercase;
    }
    .timeline-item-date{
        font-family: "Roboto Mono", sans-serif;
    }
    @media screen and (max-width: 768px) {
        .timeline-title {
            font-size: 14pt !important;
            text-align: start;
        }
        .timeline-content{
            position: relative;
            z-index: 4;
        }
        .timeline-title img{
            height: 26px;
        }
        .timeline-subtitle{
            font-size: 12pt;
            text-align: start;
        }
        .viper-container{
            left:-70px;
        }
        .timeline-content {
            margin-left: 140px;
        }
        .viper-container img{
            height: 400px;
        }
        .item-title-container{
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
        }
        .item-title{
            font-size: 13pt;
        }
        .timeline-item-desc{
            font-size: 11pt;
        }
        .timeline-item-date{
            font-size: 11pt;
        }
        .sova-container{
            display: none;
        }
    }
    @keyframes beat {
        0% {
            transform: scale(1) translateY(0);
        }

        100% {
            transform: scale(1.02) translateY(-6px);
        }
    }



</style>