
/* eslint-disable */
import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ZHD3JDUsW8yn6ZuDEGEb00lrbc21EOf2zVpxd7zelzQsNBVQxcdr4NuejLw7';
const state = {
    tournamentId: null,
    tournamentItem: null,
    playerIsParticipant: false,
    playerIsCheckedIn: false,
    participantType: null,
    isLoadingAction: false,
    participationCheckComplete: false,
    apiUrl: "https://lgame.gg/api/v1/",
    //apiUrl: "http://lgame.gg:8001/api/v1/",
    playerTeamIsParticipant: false,
    playerTeamIsCheckedIn: false,
    playerTeams: [],
    teamParticipatedId: null,
    fetchingTeams: false,
    isLoadingAction: false,
    participantType: null,
    twitchIsLive: false,
};

const actions = {
    getTournament({commit, dispatch, state}, tournamentId){
        axios.get(state.apiUrl+'tournaments/'+tournamentId)
        .then(response => {
            commit('setTournament', response.data.data);
            dispatch('checkParticipation');
        }).catch(error => {
            console.log('error');
            console.log(error);
        });
        
    },
      checkParticipation({commit, state, rootState}){

        if(!rootState.currentUser.isAuthenticated){
           
            state.participationCheckComplete = true;
            return; // user not logged in
        }
       const playerId = rootState.currentUser.user.player.id;
      
       return axios.post(state.apiUrl+'user/player/participant-in', {
           tournament_id: state.tournamentItem.id,
           player_id: playerId,
           participant_type: state.tournamentItem.settings.play_mode.size > 1 ? 'team' : 'player',
           game_id: state.tournamentItem.game_id
       })
           .then(response => {
               
               if(response.data.success === true){
                  
                   if(state.tournamentItem.settings.play_mode.size === 1){
                       commit('checkParticipation', response.data.isParticipant);
                       commit('checkPlayerIn', {
                           checkinValue: response.data.isCheckedIn
                       });
                   }else{
                       commit('checkTeamParticipation', response.data);
                       commit('checkTeamIn', {
                           checkinValue: response.data.isCheckedIn
                       });
                   }

               }
           })
           .catch(error => {
               console.log(error.response);
           })
    },
    checkPlayerIn({commit, state, rootState}, payload){
        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/checkin',{
            checkinValue: payload.checkinValue,
            participant_type: 'Player',
            player_id: playerId,
        })
            .then(response => {
                if(response.data.success){
                    commit('checkPlayerIn', {
                        participants: response.data.data,
                        checkinValue: payload.checkinValue,
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    },
    /**
     *  this function is used by player to participate in tournament
     */
    setParticipant({commit, state, rootState}, payload){
        const playerId = rootState.currentUser.user.player.id;
        const tournamentId = state.tournamentItem.id;
        console.log(payload?.customFieldsValue)
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/participate',{
            participant_id: playerId,
            participant_type: 'Player',
            custom_fields: payload?.customFieldsValue
        })
            .then(response => {
                if(response.data.success){
                   
                    commit('setPlayerIsParticipant', response.data.data);
                }
            })
            .catch(error => {
                console.log('===================================== catch error message START =========================')
                console.log(error)
                console.log('===================================== catch error message END =========================')
            });
    },

    /**
     * this function is used by a player to cancel his participation
     */
    cancelParticipation({commit, state, rootState}){

        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/remove', {
            participant_id: playerId,
            participant_type: 'Player',
        })
            .then(response => {
                
                if(response.data.success){
                    //const currentTournament = rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId);
                    //console.log(currentTournament);
                    /*if(currentTournament){

                    }

                    rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId).participants_players = response.data.data;
                    rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId)
                        .participants_players_count -= 1;*/
                    state.tournamentItem.participants_players = response.data.data;
                    commit('cancelParticipation', response.data.data);
                }
            })
    },
    triggerLoading({commit}, isLoading){
        commit('triggerLoading', isLoading);
    },
    getCurrentPlayerTeams({commit, state, rootState}){
        state.fetchingTeams = true;
        const gameId = state.tournamentItem.game_id;
        const playerId = rootState.currentUser.user.player.id;
        const playModeSize = state.tournamentItem.settings.play_mode.size
        return axios.post(state.apiUrl+'user/player/'+playerId+'/teams/by-game?playMode='+playModeSize, {
            game_id: gameId,
        })
            .then(response => {
                console.log(response.data);
                commit('setCurrentPlayerTeams', response.data.data);
            }).catch(error => {
                console.log(error.response);
            })
    },
     setParticipantTeam({commit, state, rootState}, payload){
        const teamId = payload.teamId;
        const tournamentId = state.tournamentItem.id;
        console.log(payload.customFieldsValue)
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/participate', {
            participant_id: teamId,
            participant_type: 'Team',
            custom_fields: payload.customFieldsValue,
        })
            .then(response => {
                if(response.data.success){
                    console.log('========================================== team participation log ================================')
                    console.log(response.data)
                    console.log('========================================== team participation log ================================')
                    commit('setTeamIsParticipant', {
                        participants: response.data.data,
                        participantTeamId: teamId
                    });
                }
            })
            .catch(error => {
                console.log(error.response)
            })
    },
    cancelTeamParticipation({commit, state, rootState}){
        const teamId = state.teamParticipatedId;
        const tournamentId = state.tournamentItem.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/remove', {
            participant_id: teamId,
            participant_type: 'Team',
        }).then(response => {
            console.log('========================================== team participation log ================================')
            console.log(response.data)
            console.log('========================================== team participation log ================================')
            /*if(rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId)){
                rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId)
                    .participants_teams = response.data.data;
                rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId)
                    .participants_teams_count -= 1;
            }else{
                state.tournamentItem.participants_teams = response.data.data;
            }*/
            commit('cancelTeamParticipation', response.data.data);
        }).catch(error => {
            console.log(error);
        })
    },
    checkTeamIn({commit, state, rootState}, payload){
        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/checkin',{
            team_id: payload.teamId,
            checkinValue: payload.checkinValue,
            participant_type: 'Team',
            player_id: playerId,
        })
            .then(response => {
                console.log('%ccheck team test ======================', 'color: green')
                console.log(payload)
                console.log(response)
                console.log('%ccheck team test ======================', 'color: green')
                if(response.data.success){
                    commit('checkTeamIn', {
                        participants: response.data.data,
                        checkinValue: payload.checkinValue,
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    },

};

const  mutations = {
    setTournament(state, tournament){
        state.tournamentItem = tournament;
        state.tournamentItem.bracket.structure = JSON.parse(state.tournamentItem.bracket.structure);
        state.tournamentItem.bracket.settings = JSON.parse(state.tournamentItem.bracket.settings);
        state.participantType = state.tournamentItem.settings.play_mode.size === 1 ? 'player' : 'team';
        
    },
     setPlayerIsParticipant(state, participants){
       
        state.playerIsParticipant = true;
        state.tournamentItem.participants_players = participants;
        //rootState.tournament.tournamentsList. for participants
        //rootState.tournament.tournamentsList. for count
    },
     cancelParticipation(state, participants){
        
        state.playerIsParticipant = false;
        state.playerIsCheckedIn = false;
        state.tournamentItem.participants_players = participants;
     },
    checkPlayerIn(state, payload) {
        state.playerIsCheckedIn = payload.checkinValue;
        if(payload.participants){
            state.tournamentItem.participants_players = payload.participants;
        }
    },
    checkParticipation(state, isParticipant){
        state.playerIsParticipant = isParticipant;
        state.participationCheckComplete = true;
    },
    triggerLoading(state, isLoading){
        state.isLoadingAction = isLoading;
    },
    
    setCurrentPlayerTeams(state, teams){
        state.playerTeams = teams;
        state.fetchingTeams = false;
    },
      setTeamIsParticipant(state, payload){
        state.playerTeamIsParticipant = true;
        state.tournamentItem.participants_teams = payload.participants;
        state.teamParticipatedId = payload.participantTeamId;
    },
    checkTeamParticipation(state, data){
        state.playerTeamIsParticipant = data.isParticipant;
        state.teamParticipatedId = data.team_id;
        state.participationCheckComplete = true;
    },
    cancelTeamParticipation(state, participants){
        state.playerTeamIsParticipant = false;
        state.playerTeamIsCheckedIn = false;
        state.tournamentItem.participants_teams = participants;
    },
    checkTeamIn(state, payload) {
        state.playerTeamIsCheckedIn = payload.checkinValue ;
        if(payload.participants){
            state.tournamentItem.participants_teams = payload.participants;
        }

    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
}