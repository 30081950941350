import { render, staticRenderFns } from "./PrizesCards.vue?vue&type=template&id=394ee907&scoped=true&"
import script from "./PrizesCards.vue?vue&type=script&lang=js&"
export * from "./PrizesCards.vue?vue&type=script&lang=js&"
import style0 from "./PrizesCards.vue?vue&type=style&index=0&id=394ee907&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394ee907",
  null
  
)

export default component.exports