<template>
    <div class="simple-slider-wrapper my-4 px-md-5">
        <div class="slider-content p-sm-2 p-md-5">
            <div class="content px-sm-0 px-md-5" v-if="items.length">
                <div class="items">
                    <div class="item-title-container">
                        <h5 class="item-title text-left px-2 px-md-5">{{ items[currentIndex].title }}</h5>
                    </div>
                    <div class="trip-timeline-desc position-relative">
                        <div class="m-0" v-html="items[currentIndex].content"></div>
                    </div>
                </div>
            </div>
            <dir
                class="pointers-container d-flex justify-content-between justify-content-md-around justify-content-md-center align-items-center p-0 w-100">
                <div class="btn-go btn-go-back-btn-container h-100">
                    <button class="p-2" @click="goBack" :disabled="!canGoBack">
                        <img :src="require('@/assets/btn-slider-back.svg')" height="24px" /></button>
                </div>
                <div class="pointers d-flex justify-content-center align-content-center">
                    <div class="pointer-item mr-2" :class="{ 'item-active': currentIndex == index - 1 }"
                        v-for="index of items.length" :key="index"></div>
                </div>
                <div class="btn-go btn-go-next-btn-container h-100">
                    <button class="p-2" @click="goNext" :disabled="!canGoNext">
                        <img :src="require('@/assets/btn-slider-next.svg')" height="24px" />
                    </button>
                </div>
            </dir>

        </div>
        <div class="slider-controls d-flex justify-content-between align-items-center px-md-5">
            <div class="btn-go btn-go-back-btn-container h-100">
                <button class="px-4" @click="goBack" :disabled="!canGoBack">
                    <img :src="require('@/assets/btn-slider-back.svg')" height="24px" /></button>
            </div>
            <div class="btn-go btn-go-next-btn-container h-100">
                <button class="px-4" @click="goNext" :disabled="!canGoNext">
                    <img :src="require('@/assets/btn-slider-next.svg')" height="24px" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleSlider",
    props:{
        items:{
            type: Array,
            default:()=>{
                return []
            },
        }
    },
    computed:{
        canGoBack:{
            get(){
                return this.currentIndex > 0;
            }
            
        },
        canGoNext: {
            get() {
                console.log(this.items.length)
                return this.currentIndex < this.items.length-1;
            }

        }
    },
    methods:{
        goNext(){
            if(this.currentIndex < this.items.length){
                console.log("current index: ", this.currentIndex)
                this.currentIndex = this.currentIndex + 1;
            }
        },
        goBack(){
            if (this.currentIndex > 0) this.currentIndex = this.currentIndex - 1;
        }
    },
    data(){
        return {
            currentIndex: 0,
        }
    }
    }
</script>

<style>
    .simple-slider-wrapper{
        position: relative;
        z-index:100;
    }
   
    .slider-controls{
        position: absolute;
        left: 0;
        top: 0;
        height:100%;
        width: 100%;
    }
    .pointer-item{
        width: 20px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.164);
        clip-path: polygon(2px 0, 100% 0, 100% calc(100% - 2px), calc(100% - 2px) 100%, 0 100%, 0 2px)
    }
    .item-title-container {
        background-color: var(--secondary-color);
        width: fit-content;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
    } 

    .item-title {
        font-family: "monument_extendedregular";
        text-transform: uppercase;
    }

    .timeline-item-date {
        font-family: "Roboto Mono", sans-serif;
    }
    .pointer-item.item-active{
        background-color: var(--secondary-color);
        transform: scale(1.02);
    }
    .slider-content{
        min-height: 260px;
    }
    .btn-go button{
        outline: none;
        border: none;
        height: 100%;
        background-color: transparent;
        color: white;
    }
    .btn-go button:hover {
        outline: none;
        border: none;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
    }
    button:disabled{
        background-color: transparent;
        cursor: not-allowed;
        opacity: 0.2;
    }
    .pointers-container .btn-go{
        display: none;
    }
    @media screen and (max-width: 768px) {
        .pointers-container .btn-go {
            display: flex;
        }
        .slider-controls{
            display: none !important;
        }
        .timeline-title {
            font-size: 14pt !important;
            text-align: start;
        }

        .timeline-content {
            position: relative;
            z-index: 4;
        }

        .item-title-container {
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
        }

        .item-title {
            font-size: 13pt;
        }

        .timeline-item-desc {
            font-size: 11pt;
        }

        .timeline-item-date {
            font-size: 11pt;
        }
        .pointers-container .btn-go img{
            height: 20px;
        }
    }
</style>