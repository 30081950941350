  <template>
    <div
        class="prizes-wrapper d-flex flex-column flex-lg-row justify-content-center align-items-end position-relative w-100">
        <div class="video-container">
            <video autoplay muted loop>
                <source :src="require('@/assets/valo-vid.mp4')" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="prize-header">
            <img :src="require('@/assets/prize-header.png')" height="48px" />
        </div>
        <div class="omen-container">
            <img :src="require('@/assets/omen.png')" height="620px" />
        </div>
        <div class="vct-container">
            <img :src="require('@/assets/vct-seoul.png')" width="200px" />
        </div>
        <div class="prize-card-content container py-3 py-md-5">
            <div>
                <h4 class="prize-card-title text-left mb-3">L’équipe gagnante aura la chance de remporter un voyage
                    gratuit
                    <br>
                    pour la finale du Valorant Champions à Seoul, en Corée.
                </h4>
            </div>
            <div class="my-3 my-md-5">
                <p class="prize-desc-item text-left m-0">200 USD de monnaie de jeu Valorant (VP)</p>
                <p class="prize-desc-item text-left m-0">Hébergement double (deux personnes)</p>
                <p class="prize-desc-item text-left m-0">Hôte dédié sur place</p>
            </div>
            <div class="trip-timeline-container px-2">
                <div class="row">
                    <div class="col col-sm-12 col-md-6 mb-4 px-0">
                        <div class="trip-timeline-header px-2 px-md-5">
                            <h4>Jour 1</h4>
                        </div>
                        <div class="trip-timeline-desc position-relative">
                            <p class="m-0">Arrivée dans la ville d'accueil</p>
                            <p class="m-0">Service de transport jusqu'à l'hébergement de luxe</p>
                            <p class="m-0">Dîner de bienvenue</p>
                        </div>
                    </div>
                    <div class="col col-sm-12 col-md-6 mb-4 px-0">
                        <div class="trip-timeline-header px-2 px-md-5">
                            <h4>Jour 2</h4>
                        </div>
                        <div class="trip-timeline-desc position-relative">
                            <p class="m-0">Petit déjeuner à l'hôtel</p>
                            <p class="m-0">Dîner avant/après le match (en fonction de l'ordre du jour définitif)</p>
                            <p class="m-0">Une expérience sans prix** et un accès exclusif aux champions VALORANT</p>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col col-sm-12 col-md-6 mb-4 px-0">
                        <div class="trip-timeline-header px-2 px-md-5">
                            <h4>Jour 3</h4>
                        </div>
                        <div class="trip-timeline-desc position-relative">
                            <p class="m-0">Petit déjeuner à l'hôtel</p>
                            <p class="m-0">Service de voiture de l'hôtel à l'aéroport</p>
                            <p class="m-0">Départ de la ville d'accueil</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "PrizesCards",
    }
</script>

<style lang="css" scoped>
    .prizes-wrapper{
        position: relative;
        background-image: url("@/assets/prizes-bg.webp");
        background-size: 100% 100%;
        background-position: bottom right;
        min-height: 620px;
    }
    .video-container{
        position: absolute;
        top:0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        overflow: hidden;
    }
    .video-container video{
        /* Make video to at least 100% wide and tall */
            min-width: 100%;
            min-height: 100%;
        
            /* Setting width & height to auto prevents the browser from stretching or squishing the video */
            width: auto;
            height: auto;
        
            /* Center the video */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    }
    
    .omen-container{
        position: absolute;
        top:-120px;
        right: 0;
        z-index: 0;
        animation: bounce 3.6s infinite alternate-reverse;
    }
    .vct-container{
        position: absolute;
        right: 30px;
        bottom: 60px;
    }
    .vct-container img{
        mix-blend-mode: luminosity;
    }
    .prize-header{
        position: absolute;
        top: -24px;
        left:0;
    }
    .prize-card-content{
        z-index: 1;
    }
    .prize-card-title{
        color: white;
        font-family: "Roboto";
    }
    .prize-desc-item{
        color: white;
        font-family: "Roboto Mono";
    }
    .trip-timeline-header{
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
        background-color: white;
        color: black;
        font-family: "Roboto Mono";
        width: 200px;
    }
    .trip-timeline-desc::before{
        content: "";
        position: absolute;
        top:0;
        left: 0;
        border-left: 2px solid white;
        height: inherit;
    }
    .trip-timeline-desc p{
        color: white;
        font-family: "Roboto Mono";
        text-align: left;
    }
    .sage-container{
        position: absolute;
        bottom:0;
        left:10px;
        z-index: 0;
        animation: bounce 4s infinite alternate-reverse;
    }
        @keyframes demiRotate {
            0% {
                transform: rotateY(0deg);
            }
    
            100% {
                transform: rotateY(30deg);
                /* Complete rotation */
            }
        }
    @media screen and (max-width: 768px){
        .prizes-wrapper{
            background-size: auto 100%;
        }
        .prize-header img{
            height: 24px;
        }
        .omen-container {
            top: -60px;
        }
        .omen-container img{
            height: 280px;
        }
        .sage-container img{
            height: 280px;
        }
        .prize-card-title{
            font-size: 14pt;
        }
        .trip-timeline-header{
            width: fit-content;
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
        }
        .vct-container img{
            width: 100px;
            opacity: 0.8;;
        }
        .trip-timeline-header h4{
            font-size: 13pt;
        }
        .trip-timeline-desc p{
            font-size: 11pt;
        }
        
    }
    @keyframes bounce {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(26px);
        }
    }
</style>