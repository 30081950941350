<template>
    <div class="footer-wrapper d-flex flex-column justify-content-center align-items-center">
        <div class="footer-content">
            <div class="tournament-logo">
                <img :src="require('@/assets/tournament-logo.svg')" height="80px" />
            </div>
            <div class="partners-logos my-5">
                <img :src="require('@/assets/partners.png')" height="80px" />
            </div>
            <div class="my-4">
                <RSLinks />
            </div>
            <div class="mt-4">
                <p class="footer-note">Ce tournoi est exclusivement réservé aux joueurs résidant au Maroc.</p>
            </div>
        </div>
        <div class="powered-by-lgame w-100">
            <PoweredByLgame />
        </div>
    </div>
</template>
<script>
    import PoweredByLgame from "./PoweredByLgame.vue";
import RSLinks from "./RSLinks.vue";
    export default{
        name: "MasterCardFooter",
        components:{
            PoweredByLgame,
            RSLinks
        }
    }
</script>
<style lang="css" scoped>
    .footer-wrapper{
        position: relative;
        background-image: url("@/assets/footer-bg.png");
        background-size: 100% 100%;
       
        height: 480px;
    }
    .powered-by-lgame{
        position: absolute;
        bottom:0;
    }
    .footer-note{
        color: white;
        font-family: "Roboto mono";
    }
    @media screen and (max-width: 768px) {
        .footer-wrapper {
          
            background-size: auto 100%;
            background-position:  70%;
            height: 480px;
        }
        .partners-logos img{
            height: 42px;
        }
        .footer-note{
            font-size: 0.8rem;
        }
    }
</style>