<template>
    <div class="participate-card-wrapper w-100" v-if="(!tournamentStarted && registrationStatus)">
        <!-- (!tournamentStarted && registrationStatus)  -->
        <div class="participate-card-container d-flex flex-column justify-content-center align-items-center p-md-2">
            <div class="participate-btn-wrapper w-100" v-if="(!isAuthenticated)"> <!--  -->
                <button class="participate-btn py-2 w-100 px-5" dir="rtl" :class="{'outlined': outlined}"
                    data-toggle="modal" data-target="#loginModal" @click="participateAction()">
                    Participer
                </button>
            </div>
            <div class="participate-btn-wrapper w-100" v-else>
                <button class="participate-btn  py-2 w-100 px-5" data-toggle="modal" :class="{'outlined': outlined }"
                    dir="rtl" data-target="#lgame-participation-pop-up" @click="participateAction()"
                    v-if="(!isParticipant)"> <!---->
                    Participer
                </button>
                <button class="participate-btn  py-2 px-4 px-md-5 w-100" data-toggle="modal"
                    :class="{'outlined': outlined }" data-target="#lgame-participation-pop-up"
                    @click="cancelParticipateAction()" v-else>
                    Se désinscrire
                </button>
                <h6 class="font-weight-bold" v-if="isParticipant">(Vous êtes inscrit)</h6>
            </div>
            <div class="participants-status-container d-flex justify-content-center align-items-center mt-2"
                v-if="showParticipantsStatus">
                <h6 class="participants-status m-0" :class="{ 'outlined': outlined }">
                    {{ participantCount }} équipe{{ participantCount > 1 ? 's' : '' }} inscrit{{ participantCount > 1 ?
                    's' : '' }}
                </h6>
            </div>
        </div>
        <div class="reglement my-2">
            <a href="https://drive.google.com/file/d/1SZgds70CeDjijDqudqnY3DE3j-eP-y8b/view"
                target="_blank">Règlement
                du tournoi</a>
        </div>
    </div>
    <div class="spacer" v-else style="height:130px; width:10px"></div>
</template>

<script>
/* eslint-disable */
import 'moment';
import moment from 'moment';
    export default {
        name: 'ParticipateCard',
        props:{
            outlined:{
                type: Boolean,
                default: false,
            },
            showParticipantsStatus:{
                type: Boolean,
                default: false,
            }
        },
        data(){
            return {

                participantsSize: 0,
                startDate: moment().add(7, 'days').valueOf(),
                today: moment().valueOf(),
                countDown:{
                    days: null, 
                    hours: null, 
                    minutes: null, 
                    seconds: null, 
                },
                diffTime: null,
                interval: 1000,
                duration: null,
                TYPE_PLAYER: 1,
                TYPE_TEAM: 2,
            
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.$store.state.currentTournament.tournamentItem;
                },
                set(val) {
                    return val;
                }
            },
            isAuthenticated: {
                get() {
                    return this.$store.state.currentUser.isAuthenticated;
                }
            },
            registrationStatus: { // true:  open, false: closed
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.tournamentItem.settings.registration_status;
                    }

                }
            },
            registrationEnds: {
                get() {
                    if (this.isLoaded) {
                        moment.locale('fr');
                        const dateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment(dateTime, 'DD-MM-YYYY HH:mm').format('ddd Do, MMM  [à] hA');
                    }
                }
            },
            isPastTournament: {
                get() {
                    if (this.isLoaded) {
                        const dateTime = this.$store.state.currentTournament.tournamentItem.end_date + ' ' + this.$store.state.currentTournament.tournamentItem.end_time;
                        console.log(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                        return moment().isAfter(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            checkInStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        const checkInStartsIn = this.$store.state.currentTournament.tournamentItem.settings.check_in_starts;
                        const checkinStartTime = moment(startDateTime, 'DD-MM-YYYY HH:mm').subtract(checkInStartsIn, 'minutes').toDate();
                        return moment().isBefore(moment(startDateTime, 'DD-MM-YYYY HH:mm')) && moment().isAfter(moment(checkinStartTime));
                    }
                }
            },
            tournamentStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment().isSameOrAfter(moment(startDateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            participantType: {
                get() {
                    if (this.isLoaded) {
                        const size = this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
                        return size > 1 && size !== 0 ? this.TYPE_TEAM : this.TYPE_PLAYER;
                    }
                }
            },
            tournamentSize: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.tournamentItem.settings.size;
                    }

                }
            },
            participantCount: {
                get() {
                    if (this.isLoaded) {
                        return this.participantType === this.TYPE_PLAYER ?
                            this.$store.state.currentTournament.tournamentItem.participants_players.length :
                            this.$store.state.currentTournament.tournamentItem.participants_teams.length;
                    }
                    return 0;
                }
            },
            isParticipant: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.playerIsParticipant;
                        else
                            return this.$store.state.currentTournament.playerTeamIsParticipant;
                    }
                    return false;
                }
            },
            isParticipantCheckedIn: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.playerIsCheckedIn;
                        else
                            return this.$store.state.currentTournament.playerTeamIsCheckedIn;
                    }
                }
            },
            hasCustomFields: {
                get() {
                    if (this.isLoaded) {
                        const customFields = this.$store.state.currentTournament.tournamentItem.settings.custom_fields;
                        return customFields !== null && customFields.length > 0;
                    }
                }
            },
            isLoadingAction: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.isLoadingAction;
                    }
                }
            },
            teamParticipatedId: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.teamParticipatedId;
                    }
                }
            },
            isUserAuth: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentUser.isAuthenticated;
                    }
                }
            }
    },
    watch: {
        isUserAuth(newVal, oldVal) {
            if (oldVal === false && newVal === true && !this.isParticipant) {
                this.$store.dispatch('currentTournament/checkParticipation');
            }
        },
        startDate_comp(newVal, oldVal){
            if(oldVal !== newVal && newVal !== null){
                this.initCountdown();
            }
        }
    },
         methods: {
            participateAction() {
                // participate current auth user
              if (this.isAuthenticated) {
                    this.$store.dispatch('currentTournament/triggerLoading', true);
                    if (this.participantType === this.TYPE_TEAM) {
                        this.$store.dispatch('currentTournament/getCurrentPlayerTeams');
                        this.$emit('init-pop-up', {
                            type: 'teams',
                            title: "Choisir une équipe",
                            message: "Choisir une équipe parmis vous equipes pour participer a cette tournoi:",
                            withOptions: false,
                            popUpDispatch: 'currentTournament/setParticipantTeam',
                        });
                    }
                    if (this.participantType === this.TYPE_PLAYER) {
                        if (this.hasCustomFields) {
                            this.$emit('init-pop-up', {
                                type: 'custom',
                                withOptions: true,
                                title: "Formulaire d'inscription",
                                message: 'Merci de remplire les champs suivants pour participer au tournoi',
                                options: {
                                    mainAction: 'Participer',
                                    secondAction: 'Annuler'
                                },
                                popUpDispatch: 'currentTournament/setParticipant',
                            });
                        } else {
                            this.$store.dispatch('currentTournament/setParticipant')
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        }
                    }
                } else {
                    this.$root.$emit('show-login', 'check_participation');
                }
            },
            cancelParticipateAction() {
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    console.log('cancelling player participation')
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: 'currentTournament/cancelParticipation',
                    });
                    /*this.$store.dispatch('currentTournament/cancelParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: 'currentTournament/cancelTeamParticipation'
                    });
                    console.log('cancelling team participation')
                    /*this.$store.dispatch('currentTournament/cancelTeamParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }

            },
            checkPlayerIn() {
                this.$store.dispatch('currentTournament/checkPlayerIn');
            },
            checkParticipantIn() {
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_TEAM) {
                    this.$store.dispatch('currentTournament/checkTeamIn', {
                        teamId: this.teamParticipatedId,
                        checkinValue: true,
                    }).then(() => {
                        this.$store.dispatch('currentTournament/triggerLoading', false);
                    });
                }
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$store.dispatch('currentTournament/checkPlayerIn', {
                        checkinValue: true,
                    })
                        .then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                }
            },
            cancelCheckIn() {
                //only team case done
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: 'currentTournament/checkPlayerIn',
                        dispatchData: {
                            checkinValue: false,
                        }
                    })
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: 'currentTournament/checkTeamIn',
                        dispatchData: {
                            teamId: this.teamParticipatedId,
                            checkinValue: false,
                        }
                    });
                }
            },
            viewBracket() {
                this.$emit('view-bracket');
            },
            initCountdown(){
                this.diffTime = this.startDate_comp - this.today;
                this.duration = moment.duration(this.diffTime, 'milliseconds');
                setInterval(() => {
                    this.duration = moment.duration(this.duration - this.interval, 'milliseconds');
                    this.countDown.days = this.duration.days() < 10 ? '0' + this.duration.days() : this.duration.days();
                    this.countDown.hours = this.duration.hours() < 10 ? '0' + this.duration.hours() : this.duration.hours();
                    this.countDown.minutes = this.duration.minutes() < 10 ? '0' + this.duration.minutes() : this.duration.minutes();
                    this.countDown.seconds = this.duration.seconds() < 10 ? '0' + this.duration.seconds() : this.duration.seconds();
                    this.countdownInitialized = true;
                }, this.interval);
            }
        },
        mounted(){
            

        }
    }
</script>

<style scoped>
.participate-card-container{
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.4));
    
}
.participate-btn-wrapper{
    width: 320px !important;
}
.participate-btn{
    border: 1px solid white;
    outline: none;
    color: white;
    font-family:'Roboto Mono', sans-serif;
    font-size: 14pt;
    text-transform: uppercase;
    backdrop-filter: blur(200px) saturate(300%);
    -webkit-backdrop-filter: blur(200px) saturate(300%);
    background-color: rgba(255, 255, 255, .4);
    color: #fff;
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.participate-btn-wrapper:hover{
    transform: translate(2px, 2px);
    transition: translate .5s linear;
}

.arabic-title{
    font-family: 'tajawalbold', sans-serif;
    font-size:16pt !important;
    font-weight: 600;
}
.participants-status-container{
    
}
.participants-status{
    font-family:'Tajawal', sans-serif;
    color: var(--main-color);
    font-size: 10pt;
}
.participants-status:nth-child(2){
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}
.participants-status.outlined{
    background-color: transparent;
    border: none;
    color: white !important;
}
.spacer{
    display:block;
}
.reglement a{
    color: white;
    font-family: "Roboto Mono", sans-serif;
    text-decoration: underline;
    font-size: 12pt;
}
@media screen and (max-width: 768px) {
       .spacer{
        display: none;
    }
}

@media screen and (max-width: 600px) {

    .participate-btn{
        font-size: 11pt;
    }
    .participants-status{
        font-size: 9pt;
    }
}

.participate-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, #3a87fb1c 0%, transparent 60%);
    transform: translate(-50%, -50%);
    animation: lightReflection 3s infinite;
    pointer-events: none;
}

@keyframes lightReflection {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>